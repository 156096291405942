import React from 'react';
import './App.css';
import RandomString from './RandomString'; // Düzgün içe aktarma

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <h1>Mobilya Seçimi</h1>
        <p>Coming Soon!</p>
        <p>Yeni mobilya koleksiyonumuzu keşfetmek için hazır olun.</p>
        <button className="choose-furniture-btn">Mobilya Seç</button>
      </header>
      <RandomString /> {/* RandomString bileşeni burada kullanılıyor */}
    </div>
  );
}

export default App;
