import React, { useState } from 'react';
import axios from 'axios';

const RandomString = () => {
    const [randomString, setRandomString] = useState('');

    const getRandomString = async () => {
        try {
            const response = await axios.get('http://localhost:8080/random');
            setRandomString(response.data.random_string);
        } catch (error) {
            console.error("Error fetching random string:", error);
        }
    };

    return (
        <div>
            <h1>Mobilya Generator</h1>
            <button onClick={getRandomString}>Mobilya Getir</button>
            {randomString && <p>Random String: {randomString}</p>}
        </div>
    );
};

export default RandomString;